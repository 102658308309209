import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndianRupeeSign,
  faLocationDot,
  faBriefcase,
  faAddressCard,
  faGraduationCap,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";
import {
  AutoComplete,
  InputGroup,
  TagGroup,
  Tag,
  Slider,
  SelectPicker,
  Pagination,
} from "rsuite";

const styles = {
  width: 700,
  marginBottom: 10,
  display: "flex",
};

const inputStyles = {
  marginRight: 10,
};

const separatorStyles = {
  border: "1px solid #e7e7f1",
  height: "35px",
  margin: "0 10px",
};

const centerStyle = {
  margin: "0 0 0 100px",
};

const data = [
  "Last 1 day",
  "Last 3 days",
  "Last 7 days",
  "Last 15 days",
  "Last 30 days",
].map((item) => ({ label: item, value: item }));
const data2 = [
  "Relevance",
  "Date"
].map((item) => ({ label: item, value: item }));

export default function Search() {
  const [activePage, setActivePage] = React.useState(5);
  const [tags, setTags] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");

  const removeTag = (tag) => {
    const nextTags = tags.filter((item) => item !== tag);
    setTags(nextTags);
  };

  const addTag = () => {
    const nextTags = inputValue ? [...tags, inputValue] : tags;
    setTags(nextTags);
    setInputValue("");
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      addTag();
    }
  };

  return (
    <>
      <div style={{ width: "1000px" }}>
        <div style={centerStyle}>
          <InputGroup style={styles}>
            <AutoComplete
              style={inputStyles}
              placeholder="Enter Keyword"
              className="tag-input"
              value={inputValue}
              onChange={setInputValue}
              onBlur={addTag}
              onEnter={handleKeyDown}
              onKeyDown={handleKeyDown}
            />
            <div style={separatorStyles} />
            <AutoComplete style={inputStyles} placeholder="Enter location" />
            <div style={separatorStyles} />
            <AutoComplete style={inputStyles} placeholder="Enter Keyword" />
            <InputGroup.Button tabIndex={-1}>
            </InputGroup.Button>
          </InputGroup>
          <div>
            <TagGroup>
              {tags.map((item, index) => (
                <Tag key={index} closable onClose={() => removeTag(item)}>
                  {item}
                </Tag>
              ))}
            </TagGroup>
          </div>
        </div>
        <div className="row mt-5">
          <div className="card col-4">
            <div className="card-body p-3">
              <div>
                <b>All Filters</b>
              </div>
              <hr />
              <div>
                <b>Work mode</b>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Work from Office
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Hybrid
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Remote
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Temp WFH
                  </label>
                </div>
              </div>
              <hr />
              <div>
                <b>Experience</b>
                <Slider
                  style={{ fontSize: "15px" }}
                  className="my-5 bg-dark"
                  defaultValue={15}
                  graduated
                  progress
                  min={0}
                  max={30}
                  renderMark={(mark) => {
                    if ([0,15, 30].includes(mark)) {
                      return <span>{mark}</span>;
                    }
                    return null;
                  }}
                />
              </div>
              <hr />
              <div>
                <b>Department</b>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Computer Science
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Information Technology
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    ECE
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    EEE
                  </label>
                </div>
              </div>
              <hr />
              <div>
                <b>Locations</b>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Bengalore/Bengaluru
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Hyderabad
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Pune
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Delhi
                  </label>
                </div>
              </div>
              <hr />
              <div>
                <b>Salary</b>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    0-3 LPA
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    4-8 LPA
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    9-13 LPA
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    14-20 LPA
                  </label>
                </div>
              </div>
              <hr />
              <div>
                <b>Company Type</b>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Foreign MNC
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Corporate
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Indian MNC
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Startup
                  </label>
                </div>
              </div>
              <hr />
              <div>
                <b>Education</b>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Any Postgraduate
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    M.Tech
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    B.tech
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Any Graduate
                  </label>
                </div>
              </div>
              <hr />
              <div>
                <b>Industry</b>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    IT Services
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    {" "}
                    Software Product
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Engineering & Construction
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Industrial Equipment
                  </label>
                </div>
              </div>
              <hr />
              <div>
                <b>Top Companies</b>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Accenture
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    IBM
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Infosys
                  </label>
                </div>
                <div>
                  <input type="checkbox" className="me-2 mt-2" />
                  <label htmlFor="Wfo" style={{ fontSize: "15px" }}>
                    Google
                  </label>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-6">
                  <b>Freshness</b>
                </div>
                <div className="col-6">
                  <SelectPicker data={data} style={{ width: 224 }} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-4" style={{ fontSize: "13px" }}>
                1-10 of 20781 <b>Java Jobs</b>
              </div>
              <div
                className="col-8 text-primary d-flex justify-content-end"
                style={{ fontSize: "13px" }}
              >
                <b>Send me jobs like these</b>
                <SelectPicker
                  className="ms-2"
                  size="sm"
                  data={data2}
                  style={{ width: 100 }}
                  placeholder={"Sort By : " + { data }}
                />
              </div>
            </div>
            <div className="card shadow mt-3" style={{ border: "none" }}>
              <div className="card-body p-3">
                <div>
                  <b>Senior Software Engineer - Java Developer</b>
                </div>
                <div className="text-secondary">
                  <b>Epiq Systems</b>
                </div>
                <div className="row">
                  <div className="col-3">
                    <FontAwesomeIcon
                      icon={faBriefcase}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">5-10 Yrs</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-4">
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="text-secondary mt-4"
                      style={{ height: "20px", width: "10px" }}
                    />
                    <span className="text-secondary ms-2">Not disclosed</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-5">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">
                      Hybrid-Hyderabad
                    </span>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="text-secondary mt-2"
                    style={{ height: "30px", width: "20px" }}
                  />
                  <span className="text-secondary ms-2">
                    B Tech or BE degree in Computer or related
                  </span>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    AWS . Javascript . Development . Java Development . Java .
                    Servlets . JDBC
                  </p>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    1 Day Ago
                  </p>
                </div>
              </div>
            </div>
            <div className="card shadow mt-3" style={{ border: "none" }}>
              <div className="card-body p-3">
                <div>
                  <b>Java Full Stack Development Application Developer</b>
                </div>
                <div className="text-secondary">
                  <b>Accenture</b>
                </div>
                <div className="row">
                  <div className="col-3">
                    <FontAwesomeIcon
                      icon={faBriefcase}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">6-8 Yrs</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-4">
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="text-secondary mt-4"
                      style={{ height: "20px", width: "10px" }}
                    />
                    <span className="text-secondary ms-2">12-18 LPA</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-5">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">
                      Bangalore/Bengaluru
                    </span>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="text-secondary mt-2"
                    style={{ height: "30px", width: "20px" }}
                  />
                  <span className="text-secondary ms-2">
                    B Tech or BE degree in Computer or related
                  </span>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    Hibernate . j2Ee . SDLC . JPA . Jasmine . Front End
                    Technologies
                  </p>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    1 Day Ago
                  </p>
                </div>
              </div>
            </div>
            <div className="card shadow mt-3" style={{ border: "none" }}>
              <div className="card-body p-3">
                <div>
                  <b>Senior Software Engineer - Java Developer</b>
                </div>
                <div className="text-secondary">
                  <b>Epiq Systems</b>
                </div>
                <div className="row">
                  <div className="col-3">
                    <FontAwesomeIcon
                      icon={faBriefcase}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">5-10 Yrs</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-4">
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="text-secondary mt-4"
                      style={{ height: "20px", width: "10px" }}
                    />
                    <span className="text-secondary ms-2">Not disclosed</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-5">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">
                      Hybrid-Hyderabad
                    </span>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="text-secondary mt-2"
                    style={{ height: "30px", width: "20px" }}
                  />
                  <span className="text-secondary ms-2">
                    B Tech or BE degree in Computer or related
                  </span>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    AWS . Javascript . Development . Java Development . Java .
                    Servlets . JDBC
                  </p>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    1 Day Ago
                  </p>
                </div>
              </div>
            </div>
            <div className="card shadow mt-3" style={{ border: "none" }}>
              <div className="card-body p-3">
                <div>
                  <b>Java Full Stack Development Application Developer</b>
                </div>
                <div className="text-secondary">
                  <b>Accenture</b>
                </div>
                <div className="row">
                  <div className="col-3">
                    <FontAwesomeIcon
                      icon={faBriefcase}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">6-8 Yrs</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-4">
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="text-secondary mt-4"
                      style={{ height: "20px", width: "10px" }}
                    />
                    <span className="text-secondary ms-2">12-18 LPA</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-5">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">
                      Bangalore/Bengaluru
                    </span>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="text-secondary mt-2"
                    style={{ height: "30px", width: "20px" }}
                  />
                  <span className="text-secondary ms-2">
                    B Tech or BE degree in Computer or related
                  </span>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    Hibernate . j2Ee . SDLC . JPA . Jasmine . Front End
                    Technologies
                  </p>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    1 Day Ago
                  </p>
                </div>
              </div>
            </div>
            <div className="card shadow mt-3" style={{ border: "none" }}>
              <div className="card-body p-3">
                <div>
                  <b>Senior Software Engineer - Java Developer</b>
                </div>
                <div className="text-secondary">
                  <b>Epiq Systems</b>
                </div>
                <div className="row">
                  <div className="col-3">
                    <FontAwesomeIcon
                      icon={faBriefcase}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">5-10 Yrs</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-4">
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="text-secondary mt-4"
                      style={{ height: "20px", width: "10px" }}
                    />
                    <span className="text-secondary ms-2">Not disclosed</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-5">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">
                      Hybrid-Hyderabad
                    </span>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="text-secondary mt-2"
                    style={{ height: "30px", width: "20px" }}
                  />
                  <span className="text-secondary ms-2">
                    B Tech or BE degree in Computer or related
                  </span>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    AWS . Javascript . Development . Java Development . Java .
                    Servlets . JDBC
                  </p>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    1 Day Ago
                  </p>
                </div>
              </div>
            </div>
            <div className="card shadow mt-3" style={{ border: "none" }}>
              <div className="card-body p-3">
                <div>
                  <b>Java Full Stack Development Application Developer</b>
                </div>
                <div className="text-secondary">
                  <b>Accenture</b>
                </div>
                <div className="row">
                  <div className="col-3">
                    <FontAwesomeIcon
                      icon={faBriefcase}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">6-8 Yrs</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-4">
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className="text-secondary mt-4"
                      style={{ height: "20px", width: "10px" }}
                    />
                    <span className="text-secondary ms-2">12-18 LPA</span>
                    <span style={separatorStyles}></span>
                  </div>
                  <div className="col-5">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-secondary mt-3"
                      style={{ height: "25px", width: "15px" }}
                    />
                    <span className="text-secondary ms-2">
                      Bangalore/Bengaluru
                    </span>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    className="text-secondary mt-2"
                    style={{ height: "30px", width: "20px" }}
                  />
                  <span className="text-secondary ms-2">
                    B Tech or BE degree in Computer or related
                  </span>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    Hibernate . j2Ee . SDLC . JPA . Jasmine . Front End
                    Technologies
                  </p>
                </div>
                <div>
                  <p className="text-muted mt-2" style={{ fontSize: "13px" }}>
                    1 Day Ago
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-center">
              <Pagination
                prev
                last
                next
                first
                size="sm"
                total={100}
                limit={10}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
