import React, { useEffect, useState, useCallback } from "react";
import { Card, Button, Toast, ToastContainer } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Divider, Loader, SelectPicker } from "rsuite";
import axios from "axios";
import "../scheduleVideo/ScheduleVideo.css";
import SlotBookingModal from "./SlotBookingModal";
import CreateProfile from "./CreateProfile";
import SlotsMobileView from "./SlotsMobileView";
import usePost from "../../customhooks/UsePost";
import { useForm, Controller } from "react-hook-form";

export default function AvailableSlots({
  data,
  isSlotSelected,
  setIsSlotSelected,
  bookedSlotDetails,
  profileCreate,
}) {
  const {
    control,
    register,
    setValue,
    getValues
  } = useForm()
  const { t } = useTranslation();
  const [interviewID, setInterviewID] = useState(null);
  const [companyID, setCompanyID] = useState(null);
  const [campaignID, setCampaignID] = useState(null);
  const [roundNumber, setRoundNumber] = useState(null);
  const [createProfile, setCreateProfile] = useState(false);
  const [timeZone, setTimeZone] = useState("Asia/Kolkata")
  const [dates, setDates] = useState([]);
  const [slotsInADay, setSlotsInADay] = useState([]);
  const [slotID, setSlotID] = useState(null);
  const [cancelSlot, setCancelSlot] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slots, setSlots] = useState([]);
  const [currentRound, setCurrentRound] = useState({});
  const [collectionName, setCollectionName] = useState(null);
  const [timeFormat, setTimeFormat] = useState("HH:mm");
  const [dateLoading, setDateLoading] = useState(true);
  const [payload, setPayload] = useState(null);
  const [showDates, setShowDates] = useState(false);
  const [slotLoading, setSlotLoading] = useState(true);
  const [allSlots, setAllSlots] = useState([])
  const [lastSlotBooked, setLastSlotBooked] = useState(false)
  const [profileResumeID, setProfileResumeID] = useState(null)
  const [hostID, setHostID] = useState(null)

  const {
    post: insertNotification,
    data: insertNotificationData,
    isLoading: insertNotificationLoading,
    error: insertNotificationError,
  } = usePost("/schedulevideo/insertNotification");

  const [resumeID, setResumeID] = useState("")
  const [collections, setCollections] = useState(null)
  const [clientID, setClientID] = useState(null)
  useEffect(() => {
    if (data?.campaignID) {
      setCampaignID(data?.campaignID);
    }
    if (data?.roundNumber) {
      setRoundNumber(data?.roundNumber);
    }
    if (data?.interviewID) {
      setInterviewID(data?.interviewID);
    }
    if (data?.companyID) {
      setCompanyID(data?.companyID);
    }
    if (data?.resumeID) {
      setProfileResumeID(data?.resumeID)
    }
    if (data?.userID) {
      setHostID(data?.userID)
    }
  }, [data]);

  useEffect(() => {
    if (profileCreate) {
      setCreateProfile(true);
    } else {
      setCreateProfile(false);
    }
  }, [profileCreate])

  const [timeZoneData, setTimeZoneData] = useState([])
  useEffect(() => {
    const getTimeZoneList = async () => {
      let timezonedata = await axios.get(window.location.origin + "/jsv4/webapi/getTimeZoneList",);
      timezonedata = timezonedata?.data
      if (timezonedata?.length > 0) {
        setTimeZoneData(timezonedata);
      }
    }
    getTimeZoneList();
  }, [])
  
  const [timezoneList, setTimezoneList] = useState([])
  useEffect(() => {
    if (timeZoneData && timeZoneData?.length > 0) {
      let zonelist = timeZoneData.map((zone) => {
        return {
          value: zone.timezoneName,
          label:
            zone.timezoneName +
            "(" +
            zone.timezoneAbbr +
            zone.timezone +
            ")",
        };
      })
      setTimezoneList(zonelist)
    }
  }, [timeZoneData])

  useEffect(() => {
    if (timeZoneData && timeZoneData?.length > 0) {
      let offsetArr = timeZoneData.filter((zone) => zone?.timezone == DateTime.local().toFormat("ZZ"))
      if (offsetArr && offsetArr?.length > 0) {
        setValue("timeZone", offsetArr[0]?.timezoneName)
      }
    }
  }, [timeZoneData])

  useEffect(() => {
    const candidateTimezone = DateTime.local().toFormat("z");
    setTimeZone(candidateTimezone)
  }, [])

  useEffect(() => {
    if (interviewID && companyID && roundNumber) {
      setPayload({
        interviewID: interviewID,
        roundNumber: roundNumber,
        companyID: companyID,
        target: "date",
        timeZone: timeZone
      })
    } else {
      if (hostID && companyID && profileResumeID) {
        setPayload({
          target: "date",
          from: "profile",
          companyID: companyID,
          userID: hostID,
          timeZone: timeZone,
          resumeID: profileResumeID
        })
      }
    }
  }, [interviewID, companyID, roundNumber])

  const getAvailableSlots = async () => {
    const availableSlots = await axios.get(
      window.location.origin + "/jsv4/webapi/schedulevideo/getSlotsForJobseeker",
      { params: payload }
    );
    // console.log("availableSlots", availableSlots?.data);

    if (availableSlots?.data?.status && availableSlots?.data?.result?.status) {
      setDateLoading(false);
      setSlotLoading(false)
      setSlotID(null);
      if (availableSlots?.data?.result?.dates) {
        setDates(availableSlots?.data?.result?.dates)
      }
      setSlots(availableSlots?.data?.result?.slots);
      setCampaignID(availableSlots?.data?.result?.campaignID);
      setCurrentRound(availableSlots?.data?.result?.currentRound);
      setCollectionName(availableSlots?.data?.result?.collectionName);
      setAllSlots(availableSlots?.data?.result?.slots)
      // setAllDates(availableSlots?.result?.dates)

      setResumeID(availableSlots?.data?.result?.resumeID)
      setCollections(availableSlots?.data?.result?.collections)
      setClientID(availableSlots?.data?.result?.clientID)
      // timeZoneRef.current = availableSlots?.data?.result?.currentRound?.f2fSchedule?.scheduleTimeZoneFullName

      if (
        availableSlots?.data?.result?.defaultTimeFormat != "" &&
        availableSlots?.data?.result?.defaultTimeFormat == "12 hours"
      ) {
        setTimeFormat("hh:mm a");
      }

      setShowToast(true)
    } else {
      setDateLoading(false);
      setSlotLoading(false)
    }
  }

  useEffect(() => {
    if (payload !== null) {
      getAvailableSlots();
    }
    setShowToast(false)
  }, [payload])

  useEffect(() => {
    if (slots && slots?.length > 0) {
      // let dates = slots.reduce((a, i) => {
      //   // timeZoneRef.current = slots[0]?.timeZoneFullName;
      //   a.add(
      //     DateTime.fromISO(i.startDate)
      //       .setZone(slots[0]?.timeZoneFullName)
      //       .toFormat("yyyy-MM-dd")
      //   );
      //   return a;
      // }, new Set());

      // dates = [...dates];

      // // sort dates
      // dates = dates.sort((a, b) => {
      //   return (
      //     DateTime.fromFormat(a, "yyyy-MM-dd").toMillis() -
      //     DateTime.fromFormat(b, "yyyy-MM-dd").toMillis()
      //   );
      // });
      // setDates(dates);
      // prepareSlotsInday(dates[0]);

      for (let i of slots) {
        const startTime = DateTime.fromISO(i?.startDate).setZone(timeZone).toFormat(timeFormat)
        const endTime = DateTime.fromISO(i?.endDate).setZone(timeZone).toFormat(timeFormat)
        i.slotFormat = `${startTime} - ${endTime}`
      }

      setSlotsInADay(slots);
    }
  }, [slots, timeZone, timeFormat]);

  const resFromProfile = useCallback(
    (data) => {
      // console.log("sgdhagytseydte", data);
      setInterviewID(data?.interviewID);
      setCompanyID(data?.companyID);
    },
    [data]
  );

  const [notAvailableSlots, setNotAvailableSlots] = useState([]);
  const [alreadyBookedSlots, setAlreadyBookedSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const bookedSlot = (data) => {
    // console.log("xxxxxxxxx", data);

    if (data?.status) {
      if (data?.result?.status) {
        setIsSlotSelected(true);
        bookedSlotDetails(data?.result);
      } else {
        setSelectedSlot(null);
        if (
          data?.result?.message &&
          data?.result?.message != "" &&
          data?.result?.message.includes("you have already booked a slot")
        ) {
          // console.log("already booked", data?.result?.slotID);
          setAlreadyBookedSlots([...alreadyBookedSlots, data?.result?.slotID]);
        } else if (
          data?.result?.message &&
          data?.result?.message != "" &&
          data?.result?.message.includes("Slot not available")
        ) {
          // console.log("slot not available", data?.result?.slotID);
          setNotAvailableSlots([...notAvailableSlots, data?.result?.slotID]);
        }
      }
    }
  };

  const setSlotsView = () => {
    setShowDates(true);
  };
  const handleBackToDates = () => {
    setShowDates(false);
  };
  const [buttonSize, setButtonSize] = useState('md');


  useEffect(() => {
    const handleResize = () => {
      setButtonSize(typeof window !== 'undefined' && window.innerWidth > 1880 ? "lg" : "md");
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const prepareSlotsInday = async (date) => {
    if (interviewID != null && roundNumber != null) {
      setPayload({
        interviewID: interviewID,
        roundNumber: roundNumber,
        companyID: companyID,
        target: "slot",
        date: date,
        timeZone: timeZone
      })
    } else {
      if (hostID != null && companyID != null) {
        setPayload({
          target: "slot",
          date: date,
          timeZone: timeZone,
          from: "profile",
          companyID: companyID,
          userID: hostID,
          resumeID: profileResumeID
        })
      }
    }

    setSlotLoading(true)
    // let slotsInDay = slots.filter(
    //   (x) =>
    //     DateTime.fromISO(x.startDate)
    //       .setZone(slots[0]?.timeZoneFullName)
    //       .startOf("day")
    //       .toFormat("yyyy-MM-dd") == date
    // );
    // setSlotsInADay(slotsInDay);
  };

  useEffect(() => {
    if (dates && dates.length == 1) {
      const dateInArray = dates[0]
      const timeZoneName = allSlots?.[0]?.timeZoneFullName;
      const todayISO = DateTime.now().toUTC().setZone(timeZoneName).toISODate()

      const userIds = Array.from(new Set(allSlots.map(item => item.userID)));

      if (dateInArray === todayISO) {
        let object = {
          interviewID: interviewID,
          companyID: companyID,
          roundNumber: roundNumber,
          campaignID: campaignID,
          userIds: userIds,
          from: "lastDate"
        }

        const notifyHost = insertNotification(object);
      }
    }
  }, [dates])

  useEffect(() => {
    if (allSlots && allSlots.length == 1 && lastSlotBooked) {
      const userIds = Array.from(new Set(allSlots.map(item => item.userID)));
      let object = {
        interviewID: interviewID,
        companyID: companyID,
        roundNumber: roundNumber,
        campaignID: campaignID,
        userIds: userIds,
        from: "lastSlot"
      }
      const notifyHost = insertNotification(object);
    }
  }, [lastSlotBooked])

  const handleTimezoneChange = (value) => {
    setValue("timeZone", value)
    setTimeZone(value)
    if (value != timeZone) {
      if (interviewID && companyID && roundNumber) {
        setPayload({
          interviewID: interviewID,
          roundNumber: roundNumber,
          companyID: companyID,
          target: "date",
          timeZone: value
        })
      } else {
        if (hostID && companyID && profileResumeID) {
          setPayload({
            target: "date",
            from: "profile",
            companyID: companyID,
            userID: hostID,
            timeZone: value,
            resumeID: profileResumeID
          })
        }
      }
      setShowToast(false)
      setToastMsg(t("timeSlotsAreConvertedInYourSelectedTimezone", { defaultValue: "Time slots are converted in your selected timezone." }))
    }
  }

  const [showToast, setShowToast] = useState(false)
  const handleToastClose = () => setShowToast(false)
  const [toastMsg, setToastMsg] = useState(t("timeSlotsAreConvertedInYourLocalTimezone", { defaultValue: "Time slots are converted in your local timezone." }))

  return (
    <>
      {createProfile ? (
        <CreateProfile
          campaignID={campaignID}
          roundNumber={roundNumber}
          data={resFromProfile}
        />
      ) : null}
      <div className="d-lg-block d-none">
        <div className="row">
          <div className="col-4 datesContainer  ">
            <Card className=" border  ">
              <Card.Header className="py-0">
                <div className="d-flex justify-content-start">
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    size="2x"
                    className="ms-3 me-3 mt-3 text-secondary "
                  />
                  <b className=" textMorgin text-secondary">
                    <h5>
                      <Trans i18nKey="selectDate" defaults="Select Date" />
                    </h5>
                  </b>
                </div>
              </Card.Header>

              <Divider className="mt-2 mb-0" />

              <Card.Body className="datesListContainer ">
                {dateLoading ? (
                  <Loader backdrop />
                ) : (
                  <>
                    {dates.length > 0 ? (
                      dates.map((date) => {
                        return (
                          <div
                            key={date}
                            onClick={() => {
                              prepareSlotsInday(date);
                              setCancelSlot(null);
                            }}
                            className={
                              `col-12 my-2 shadow-sm bg-white rounded text-center textSize h-5 date-container ` +
                              (DateTime.fromISO(slotsInADay[0]?.startDate)
                                .setZone(timeZone)
                                .startOf("day")
                                .toFormat("yyyy-MM-dd") == date
                                ? `text-primary border-primary border`
                                : "border-muted border")
                            }
                          >
                            {DateTime.fromFormat(date, "yyyy-MM-dd").toFormat(
                              "cccc, LLLL d, yyyy"
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-12 d-flex justify-content-center align-items-center h-100 noDatesAvailabe">
                        <div className="col-4 text-center">
                          <Trans
                            i18nKey="noDatesAvailable"
                            defaults="No Dates Available"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
          <div className="col-8 slotContainer">
            <Card className=" border ">
              <Card.Header className="py-0">
                <div className="row align-items-center d-flex">
                  <div className="col-4 d-flex justify-content-start">
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      size="2x"
                      className="ms-3 me-3 mt-3 text-secondary"
                    />
                    <b className=" textMorgin text-secondary">
                      <h5>
                        <Trans i18nKey="selectSlot" defaults="Select Slot" />
                      </h5>
                    </b>
                  </div>
                  <div className="col-8 d-flex justify-content-end align-items-end">
                    <h5 className="textMorgin pe-2">
                      <span className="text-muted">
                        {/* {" "} */}
                        <Trans i18nKey="timeZone" defaults="Time Zone" />
                      </span>
                      {/* {" "}  {":"} {timeZone} */}
                    </h5>
                    <Controller
                      control={control}
                      name="timeZone"
                      refs={register("timeZone", {
                        required: true,
                      })}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <SelectPicker
                          data={timezoneList && timezoneList?.length > 0 ? timezoneList : []}
                          placement="bottomEnd"
                          className="w-50"
                          onChange={handleTimezoneChange}
                          cleanable={false}
                          value={value}
                        />
                      )}
                    />
                  </div>
                </div>
              </Card.Header>
              <Divider className="mt-2 mb-0" />
              <Card.Body className="slotsListContainer justify-content-center">
                {slotLoading ? (
                  <Loader backdrop />
                ) : (
                  <>
                    {slotsInADay.length > 0 ? (
                      <div className="row align-items-center">
                        {slotsInADay.map((slot) => {
                          return (
                            <div className="col-lg-6 col-xl-4" key={slot?._id}>
                              {(alreadyBookedSlots?.length > 0 &&
                                alreadyBookedSlots?.includes(slot?._id)) ||
                                (notAvailableSlots?.length > 0 &&
                                  notAvailableSlots?.includes(slot?._id)) ? (
                                <div className="slot-list-item shadow-sm rounded text-center my-2 p-3 border border-primary text-primary notAvailable ">
                                  {slot?.slotFormat}
                                  {/* {`${DateTime.fromISO(slot?.startDate)
                                    .setZone(slot?.timeZoneFullName)
                                    .toFormat(timeFormat)} - ${DateTime.fromISO(
                                      slot?.endDate
                                    )
                                      .setZone(slot?.timeZoneFullName)
                                      .toFormat(timeFormat)}`} */}
                                </div>
                              ) : (
                                <>
                                  {slotID == slot?._id ? (
                                    <div className="row d-flex justify-content-around mx-1">
                                      <div className="col-6">
                                        <Button
                                          size={buttonSize}
                                          className="btn-secondary-soft"
                                          variant="light"
                                          onClick={() => {
                                            setSlotID(null);
                                            setCancelSlot(slot?._id);
                                          }}
                                        >
                                          <Trans
                                            i18nKey="cancel"
                                            defaults="Cancel"
                                          />
                                        </Button>
                                      </div>
                                      <div className="col-6 text-end">
                                        <Button
                                          variant="primary"
                                          size={buttonSize}
                                          className="btn-soft "
                                          onClick={() => setSelectedSlot(slot)}
                                        >
                                          <Trans
                                            i18nKey="confirm"
                                            defaults="Confirm"
                                          />
                                        </Button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => {
                                        setSlotID(slot?._id);
                                        setCancelSlot(null);
                                      }}
                                      className={`slot-list-item shadow-sm rounded bg-white text-center cursor-pointer my-2 p-3 slot ${cancelSlot == slot?._id
                                        ? "border border-primary text-primary"
                                        : "border border-muted"
                                        }`}
                                    >
                                      {slot?.slotFormat}
                                      {/* {`${DateTime.fromISO(slot?.startDate)
                                        .setZone(slot?.timeZoneFullName)
                                        .toFormat(
                                          timeFormat
                                        )} - ${DateTime.fromISO(slot?.endDate)
                                          .setZone(slot?.timeZoneFullName)
                                          .toFormat(timeFormat)}`} */}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="col-12 d-flex justify-content-center align-items-center h-100">
                        <div className="text-center">
                          <Trans
                            i18nKey="noSlotsAvailable"
                            defaults="No Slots Available"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <div className="d-lg-none d-block">
        <SlotsMobileView
          dates={dates}
          slotsInADay={slotsInADay}
          slots={slots}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          prepareSlotsInday={prepareSlotsInday}
          timeZone={timeZone}
          alreadyBookedSlots={alreadyBookedSlots}
          setAlreadyBookedSlots={setAlreadyBookedSlots}
          notAvailableSlots={notAvailableSlots}
          setNotAvailableSlots={setNotAvailableSlots}
          slotID={slotID}
          setSlotID={setSlotID}
          selectedSlot={selectedSlot}
          setSelectedSlot={setSelectedSlot}
          cancelSlot={cancelSlot}
          setCancelSlot={setCancelSlot}
          timeFormat={timeFormat}
          data={data}
          isSlotSelected={isSlotSelected}
          setIsSlotSelected={setIsSlotSelected}
          bookedSlotDetails={bookedSlotDetails}
          profileCreate={profileCreate}
          dateLoading={dateLoading}
          control={control}
          register={register}
          timezoneList={timezoneList}
          setTimeZone={setTimeZone}
          setPayload={setPayload}
          interviewID={interviewID}
          companyID={companyID}
          roundNumber={roundNumber}
        />
      </div>

      {selectedSlot && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <SlotBookingModal
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
            timeFormat={timeFormat}
            campaignID={campaignID}
            interviewID={interviewID}
            companyID={companyID}
            currentRound={currentRound}
            collectionName={collectionName}
            bookedSlot={bookedSlot}
            setSlotID={setSlotID}
            setLastSlotBooked={setLastSlotBooked}
            // sendbackResponse={responseCallback}
            resumeID={resumeID}
            collections={collections}
            clientID={clientID}
            timeZone={timeZone}
            profileResumeID={profileResumeID}
          />
        </React.Suspense>
      )}
      {
        showToast && (
          <ToastContainer
            className="p-4"
            position={"top-end"}
          >
            <Toast show={showToast} onClose={handleToastClose}>
              <Toast.Header>
                <strong className="me-auto">
                  <Trans i18nKey="info" defaults="info!" />
                </strong>
              </Toast.Header>
              <Toast.Body className="bg-white">{toastMsg}</Toast.Body>
            </Toast>
          </ToastContainer>
        )
      }
    </>
  );
}