import React, { useEffect, useState, useContext } from "react";
import { Controller } from "react-hook-form";
import { Popover, Tooltip, Whisper, List, Checkbox, Placeholder } from "rsuite";
import { Trans } from "react-i18next";
import { t } from "i18next";
import Loading from "../../../../Loder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusCircle,
  faPlusCircle,
  faXmark,
  faRotateRight,
  faCircleDot,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import usePost from "../../../../customhooks/UsePost";
import { useLoaderData } from "react-router-dom";
import UserContext from "../../../../app/context/UserContext";

const ExperienceDescription = ({
  control,
  register,
  expIndex,
  errors,
  setError,
  getValues,
  setValue,
  handleSubmit,
  itemData,
  // handleGeneratedesc,
  // handlerewrite,
  // generatedescriptionLoading,
  // generatedescrewriteloading,
  // aiSuggestions,
  // aiRewriteSuggetions,
  experienceList,
  setshowapprove,
  setexshowapprove,
  type,
  showGenerateButton
  // rewriteCount,
  // generateCount
}) => {
  let loaderDetails = useLoaderData();
  const { user, setUserDetails } = useContext(UserContext);
  const triggerContactDetailsRef = React.useRef();
  const rewriteRef = React.useRef();
  const [generateCount, setGenerateCount] = useState(0);
  const [rewriteCount, setRewriteCount] = useState(0);
  const [aiRewriteSuggetions, setAIRewriteSuggestions] = useState([]);
  const [aiSuggestions, setAISuggestions] = useState([]);
  const [selectedItems, setSelectedItems] = useState(
    getValues().experience[expIndex]?.description || ""
  );
  const [showText, setShowText] = useState(
    getValues().experience[expIndex]?.description || ""
  );
  const [currentIndex, setCurrentIndex] = useState(
    getValues().experience[expIndex]?.description?.length || 0
  );
  const [selectedText, setSelectedText] = useState("");
  const [selectedTextCount, setSelectedTextCount] = useState(0);

  const {
    post: generatedescription,
    data: generatedescriptionData,
    isLoading: generatedescriptionLoading,
    error: generatedescriptionError,
  } = usePost("/user/generatedescription");

  const {
    post: generatedescrewrite,
    data: generatedescrewritedata,
    isLoading: generatedescrewriteloading,
    error: generatedescrewriteerror,
  } = usePost("/user/generatedescrewrite");

  const handlerewrite = (data, index) => {
    const data3 = {
      selectedLine: data,
      jobTitle: getValues().experience[index].jobTitle,
      skills: getValues().experience[index]?.skills?.map((x) => {
        return { skill: x?.text, yoe: x?.yearsOfExperience };
      }),
      // companyID: loaderDetails?.companyID ?? user?.companyID ?? loaderDetails?.jobDetails?.companyID,
      companyID:
        loaderDetails?.companyID &&
        loaderDetails?.companyID !== undefined &&
        loaderDetails?.companyID !== "undefined"
          ? loaderDetails?.companyID
          : loaderDetails?.jobDetails?.companyID &&
            loaderDetails?.jobDetails?.companyID !== undefined &&
            loaderDetails?.jobDetails?.companyID !== "undefined"
          ? loaderDetails?.jobDetails?.companyID
          : user?.companyID,
      rewriteFor: "experience",
      generateFor: "points",
    };
    generatedescrewrite(data3)
      .then((res) => {
        if (res?.status) {
          setAIRewriteSuggestions(res?.result?.descriptionPoints);
          setAISuggestions([]);
          setRewriteCount(rewriteCount + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGeneratedesc = (data, index) => {
    // setAISuggestions(["developer","java dveloper","python developer","react developer"])
    console.log(data, index);
    const data2 = {
      jobTitle: getValues().experience[index].jobTitle,
      skills: getValues().experience[index]?.skills?.map((x) => {
        return { skill: x?.text, yoe: x?.yearsOfExperience };
      }),
      // companyID: loaderDetails?.companyID ?? user?.companyID,
      companyID:
        loaderDetails?.companyID &&
        loaderDetails?.companyID !== undefined &&
        loaderDetails?.companyID !== "undefined"
          ? loaderDetails?.companyID
          : loaderDetails?.jobDetails?.companyID &&
            loaderDetails?.jobDetails?.companyID !== undefined &&
            loaderDetails?.jobDetails?.companyID !== "undefined"
          ? loaderDetails?.jobDetails?.companyID
          : user?.companyID,
      generateFor: "points",
    };
    generatedescription(data2)
      .then((res) => {
        if (res?.status) {
          console.log(res?.result?.description, "descriptionvvvvvvv");
          setGenerateCount(generateCount + 1);
          setAISuggestions(res?.result?.descriptionPoints);
          setAIRewriteSuggestions([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckboxChange = (item) => {
    // console.log(type,"rtoypppppppppp");
    if (type && type === "careersdescription") {
      setexshowapprove(true);
    } else {
      setshowapprove(true);
    }
    // if(selectedTextCount === 1){
    //   setCurrentIndex(0);
    //   setSelectedItems(`• ${item}\n`);
    //   setShowText("");
    // }else{
    if (selectedItems?.includes(item)) {
      setShowText(selectedItems?.replace(`• ${item}\n`, ""));
      setSelectedItems(selectedItems?.replace(`• ${item}\n`, ""));
      setCurrentIndex(selectedItems?.replace(`• ${item}\n`, "")?.length);
    } else {
      let str = selectedItems?.replace(/^\n+/, "").replace(/\n{2,}/g, "\n");
      if (str != "" && !str.endsWith("\n")) {
        setSelectedItems(`${str}\n• ${item}\n`);
        setCurrentIndex(showText?.length);
      } else {
        setSelectedItems(`${str}• ${item}\n`);
        setCurrentIndex(showText?.length);
      }
    }
    // }
  };
  useEffect(() => {
    if (selectedItems === "") {
      if (type === "careersdescription") {
        setexshowapprove(false);
      } else {
        setshowapprove(false);
      }
    }
  }, [selectedItems]);
  useEffect(() => {
    setValue(
      `experience[${expIndex}].description`,
      showText?.replace(/^\n+/, "").replace(/\n{2,}/g, "\n")
    );
    setShowText(showText?.replace(/^\n+/, "").replace(/\n{2,}/g, "\n"));
  }, [showText]);

  const [descriptionWidth, setDescriptionWidth] = useState(0);

  useEffect(() => {
    const descriptionElements = document.querySelectorAll(
      ".descriptiontextbox"
    );
    let maxWidth = 0;

    descriptionElements.forEach((element) => {
      const width = element.getBoundingClientRect().width;
      if (width > maxWidth) {
        maxWidth = width;
      }
    });

    setDescriptionWidth(maxWidth);
  }, [getValues()]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentIndex < selectedItems?.length) {
        setShowText((prevText) => prevText + selectedItems[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(timer);
      }
    }, 10);

    return () => {
      clearTimeout(timer);
    };
  }, [currentIndex, selectedItems]);

  useEffect(() => {}, [showText]);

  const popOverOpen = () => {
    triggerContactDetailsRef?.current?.open();
  };
  const rewriteOpen = () => {
    rewriteRef?.current?.open();
  };
  const rewritePopOverclose = () => {
    setrewritediv(false);
    setSelectedText("");
    rewriteRef?.current?.close();
  };
  const PopOverclose = () => {
    triggerContactDetailsRef?.current?.close();
  };
  // console.log(rewriteCount,"rewriteCountrewriteCount");
  // const [divPosition, setDivPosition] = useState(150);
  const [rewritediv, setrewritediv] = useState(false);
  const handleTextSelection = (event) => {
    if (getValues().experience[expIndex].description != "• ") {
      const textareaValue = event.target.value;
      const selectionStart = event.target.selectionStart;
      const selectionEnd = event.target.selectionEnd;

      // Find the boundaries of the line containing the selected word
      let lineStart = textareaValue.lastIndexOf("\n", selectionStart - 1) + 1;
      let lineEnd = textareaValue.indexOf("\n", selectionStart);
      if (lineEnd === -1) {
        lineEnd = textareaValue.length;
      }

      // Get the entire line containing the selected word
      const selectedLine = textareaValue.substring(lineStart, lineEnd);

      console.log("Line containing the selected word:", selectedLine);
      setrewritediv(false);
      const selection = window.getSelection();
      const selectedText = selection.toString().trim();
      setSelectedText(selectedLine);
    }
    // const lineHeight = parseInt(
    //   window.getComputedStyle(textAreaRef.current).lineHeight
    // );
    // const lines = getValues(`experience[${expIndex}].description`).split("\n");
    // const targetIndex = lines.findIndex((line) => line.trim() === selectedText);
    // const height = lineHeight * (targetIndex + 1); // +1 because array index starts from 0
    // setDivPosition(height+20);
  };
  // console.log(selectedText,"sellllllllllllllllllllllllllllll");
  // const selectedItemsText = selectedItems.map((item) => `- ${item}`).join('\n');
  // console.log(selectedItems, "lsssssssssssssssssss");
  // console.log(getValues(),"hiii");
  // console.log(getValues()?.experience[expIndex], "hloo");
  // console.log(showText, "kkkkkkkkk");
  // console.log(getValues()?.experience[expIndex].description?.length,selectedItems?.length,showText?.length,currentIndex,"ppppppppppppppppp");

  const handleaddrewrite = (point) => {
    if (type && type === "careersdescription") {
      setexshowapprove(true);
    } else {
      setshowapprove(true);
    }
    console.log(point);
    const description = getValues().experience[expIndex].description;

    if (description.includes(selectedText)) {
      const updatedDescription = description.replace(
        selectedText,
        `• ${point}`
      );
      // Now you can update the description in your form state
      setValue(`experience[${expIndex}].description`, updatedDescription);
      setrewritediv(false);
      setSelectedText("");
      setShowText(getValues().experience[expIndex].description);
    }
  };
  const cardRef = React.useRef(null);

  useEffect(() => {
    if (rewritediv) {
      const selectedLi = document.querySelector(
        `.seclecdrewritetext${expIndex}`
      );
      if (selectedLi) {
        // console.log("Scrolling into view:", selectedLi);

        selectedLi.scrollIntoView({});
      }
    }
  }, [rewritediv]);
  const handleFocus = () => {
    console.log("ityiuyiuo");
    if (getValues().experience[expIndex]?.description === "") {
      setValue(`experience[${expIndex}].description`, "• ");
    }
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setSelectedText("");
      event.preventDefault(); // Prevent the default action (new line)

      const start = event.target.selectionStart;
      const end = event.target.selectionEnd;

      const currentText = getValues().experience[expIndex]?.description || "";
      const updatedText =
        currentText.substring(0, start) + "\n• " + currentText.substring(end);

      setValue(`experience[${expIndex}].description`, updatedText, {
        shouldValidate: true,
        shouldDirty: true,
      });

      // Set cursor position after the bullet point
      setTimeout(() => {
        event.target.selectionStart = event.target.selectionEnd = start + 2;
        // event.target.focus()
      }, 0);
    }
    if (event.key === "Backspace") {
      const start = event.target.selectionStart;
      setTimeout(() => {
        event.target.selectionStart = event.target.selectionEnd = start - 1;
        // event.target.focus()
      }, 0);
    }
  };
  return (
    <>
      <div className="form-group position-relative descriptiontextbox">
        <div className="row">
          <div className="col-4">
          <span>
            <label>
              {t("description", {
                defaultValue: "Description",
              })}
            </label>
          </span>
          </div>
          {showGenerateButton && <div className="col-8 d-flex justify-content-end">
            {getValues()?.experience[expIndex].description?.length > 0 && (
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={
                  <Tooltip>
                    Click on the existing line to rewrite or create a new line
                    to generate a new sentence
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="me-3 mt-2 text-primary"
                  size="lg"
                />
              </Whisper>
            )}
            {/* </span> */}

            {getValues()?.experience[expIndex].jobTitle !== "" &&
            getValues()?.experience[expIndex]?.jobTitle?.length > 0 ? (
              <>
                {selectedText && selectedText != "" ? (
                  <>
                  {rewriteCount >= 5 ? (
                <>
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={<Tooltip>You can rewrite only 5 times</Tooltip>}
                  >
                    <button
                      className="rounded-pill"
                      style={{
                        backgroundImage:
                          "linear-gradient(to bottom right, #484b4e, #484b4e)",
                        padding: "5px 15px" 
                      }}
                      disabled
                    >
                      <span className="text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          className="icon-sm shrink-0"
                        >
                          <path
                            fill="currentColor"
                            d="M6.394 4.444c.188-.592 1.024-.592 1.212 0C8.4 8.9 9.1 9.6 13.556 10.394c.592.188.592 1.024 0 1.212C9.1 12.4 8.4 13.1 7.606 17.556c-.188.592-1.024.592-1.212 0C5.6 13.1 4.9 12.4.444 11.606c-.592-.188-.592-1.024 0-1.212C4.9 9.6 5.6 8.9 6.394 4.444m8.716 9.841a.41.41 0 0 1 .78 0c.51 2.865.96 3.315 3.825 3.826.38.12.38.658 0 .778-2.865.511-3.315.961-3.826 3.826a.408.408 0 0 1-.778 0c-.511-2.865-.961-3.315-3.826-3.826a.408.408 0 0 1 0-.778c2.865-.511 3.315-.961 3.826-3.826Zm2.457-12.968a.454.454 0 0 1 .866 0C19 4.5 19.5 5 22.683 5.567a.454.454 0 0 1 0 .866C19.5 7 19 7.5 18.433 10.683a.454.454 0 0 1-.866 0C17 7.5 16.5 7 13.317 6.433a.454.454 0 0 1 0-.866C16.5 5 17 4.5 17.567 1.317"
                          ></path>
                        </svg>
                      </span>
                      <span className="mx-2 text-white">AI Rewrite</span>
                    </button>
                  </Whisper>
                </>
              ) :
              <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={
                      <Tooltip>Click here to rewrite the sentence
                      </Tooltip>
                    }
                  >
                    <span className="sparkle-button ms-1">
                    <button
                      className="gen animated-button-shake"
                      onClick={() => {
                        setrewritediv(true);
                        const selectedLi = document.querySelector(
                          ".seclecdrewritetext"
                        );

                        handlerewrite(selectedText, expIndex);
                        setTimeout(() => {
                          rewriteOpen();
                        }, [10]);
                      }}
                    >
                      <span className="spark"></span>
                      <span className="backdrop"></span>
                      <svg
                        className="sparkle"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                          fill="black"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                          fill="black"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                          fill="black"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <span className="text">AI Rewrite</span>
                    </button>
                  </span>
                  </Whisper>}</>
                ) : 
                <>{generateCount >= 5 ? (
                  <>
                    <Whisper
                      placement="top"
                      controlId="control-id-hover"
                      trigger="hover"
                      speaker={<Tooltip>You can generate only 5 times</Tooltip>}
                    >
                      <button
                        className="rounded-pill"
                        style={{
                          backgroundImage:
                            "linear-gradient(to bottom right, #484b4e, #484b4e)",
                          padding: "5px 15px" 
                        }}
                        disabled
                      >
                        <span className="text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 24 24"
                            className="icon-sm shrink-0"
                          >
                            <path
                              fill="currentColor"
                              d="M6.394 4.444c.188-.592 1.024-.592 1.212 0C8.4 8.9 9.1 9.6 13.556 10.394c.592.188.592 1.024 0 1.212C9.1 12.4 8.4 13.1 7.606 17.556c-.188.592-1.024.592-1.212 0C5.6 13.1 4.9 12.4.444 11.606c-.592-.188-.592-1.024 0-1.212C4.9 9.6 5.6 8.9 6.394 4.444m8.716 9.841a.41.41 0 0 1 .78 0c.51 2.865.96 3.315 3.825 3.826.38.12.38.658 0 .778-2.865.511-3.315.961-3.826 3.826a.408.408 0 0 1-.778 0c-.511-2.865-.961-3.315-3.826-3.826a.408.408 0 0 1 0-.778c2.865-.511 3.315-.961 3.826-3.826Zm2.457-12.968a.454.454 0 0 1 .866 0C19 4.5 19.5 5 22.683 5.567a.454.454 0 0 1 0 .866C19.5 7 19 7.5 18.433 10.683a.454.454 0 0 1-.866 0C17 7.5 16.5 7 13.317 6.433a.454.454 0 0 1 0-.866C16.5 5 17 4.5 17.567 1.317"
                            ></path>
                          </svg>
                        </span>
                        <span className="mx-2 text-white">AI Generate</span>
                      </button>
                    </Whisper>
                  </>
                ) :
                (
                  <span className="sparkle-button ms-1">
                  <button
                    className="gen"
                    onClick={() => {
                      handleGeneratedesc(itemData, expIndex);
                      setSelectedTextCount(1);
                      popOverOpen();
                    }}
                  >
                    <span className="spark"></span>
                    <span className="backdrop"></span>
                    <svg
                      className="sparkle"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                        fill="black"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span className="text">AI Generate</span>
                  </button>
                </span>
                )}</>
                }
              </>
            ) : (
              <span className="" style={{ cursor: "not-allowed" }}>
                
              <>
              <Whisper
                  followCursor
                  placement={"bottom"}
                  speaker={
                    <Tooltip
                      className="d-flex align-items-center justify-content-center"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Add Job Title to Generate Jobdescription
                    </Tooltip>
                  }
                >
                    <button
                      className="rounded-pill"
                      style={{
                        backgroundImage:
                          "linear-gradient(to bottom right, #484b4e, #484b4e)",
                        padding: "5px 15px" 
                      }}
                      disabled
                    >
                      <span className="text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          className="icon-sm shrink-0"
                        >
                          <path
                            fill="currentColor"
                            d="M6.394 4.444c.188-.592 1.024-.592 1.212 0C8.4 8.9 9.1 9.6 13.556 10.394c.592.188.592 1.024 0 1.212C9.1 12.4 8.4 13.1 7.606 17.556c-.188.592-1.024.592-1.212 0C5.6 13.1 4.9 12.4.444 11.606c-.592-.188-.592-1.024 0-1.212C4.9 9.6 5.6 8.9 6.394 4.444m8.716 9.841a.41.41 0 0 1 .78 0c.51 2.865.96 3.315 3.825 3.826.38.12.38.658 0 .778-2.865.511-3.315.961-3.826 3.826a.408.408 0 0 1-.778 0c-.511-2.865-.961-3.315-3.826-3.826a.408.408 0 0 1 0-.778c2.865-.511 3.315-.961 3.826-3.826Zm2.457-12.968a.454.454 0 0 1 .866 0C19 4.5 19.5 5 22.683 5.567a.454.454 0 0 1 0 .866C19.5 7 19 7.5 18.433 10.683a.454.454 0 0 1-.866 0C17 7.5 16.5 7 13.317 6.433a.454.454 0 0 1 0-.866C16.5 5 17 4.5 17.567 1.317"
                          ></path>
                        </svg>
                      </span>
                      <span className="mx-2 text-white">AI Generate</span>
                    </button>
                  </Whisper>
              </>
              </span>
            )}
          </div>}
        </div>

        {!rewritediv ? (
          <Controller
            control={control}
            name={`experience[${expIndex}].description`}
            refs={register(`experience[${expIndex}].description`, {
              required: false,
            })}
            render={({ field: { onChange, onBlur, value, refs } }) => (
              <>
                <Whisper
                  placement="top"
                  trigger={"click"}
                  ref={triggerContactDetailsRef}
                  speaker={
                    <Popover
                      className="text-dark"
                      style={{
                        width: `${descriptionWidth}px`,
                        height: "200px",
                        overflowX: "hidden",
                        overflowY: "scroll",
                      }}
                    >
                      {generatedescriptionLoading ||
                      generatedescrewriteloading ? (
                        <Placeholder.Grid columns={2} active />
                      ) : (
                        <>
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5 className="m-0">
                              <Trans
                                i18nKey="suggestions"
                                defaults="Suggestions"
                              />
                            </h5>

                            <div>Select Existing Text to Rewrite</div>
                          </div>
                          {(aiSuggestions && aiSuggestions?.length > 0) ||
                          (aiRewriteSuggetions &&
                            aiRewriteSuggetions?.length > 0) ? (
                            <>
                              <List>
                                <>
                                  {aiSuggestions &&
                                  aiSuggestions?.length > 0 ? (
                                    <>
                                      {aiSuggestions?.map((point, i) => (
                                        <List.Item
                                          key={i}
                                          onClick={() => {
                                            setSelectedTextCount(
                                              selectedTextCount + 1
                                            );
                                            handleCheckboxChange(point);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span>
                                            {selectedItems?.includes(point) ? (
                                              <>
                                                <FontAwesomeIcon
                                                  className="me-1 bg-white text-primary"
                                                  icon={faMinusCircle}
                                                  size="lg"
                                                ></FontAwesomeIcon>
                                              </>
                                            ) : (
                                              <>
                                                <FontAwesomeIcon
                                                  className="me-1 bg-white text-primary"
                                                  icon={faPlusCircle}
                                                  size="lg"
                                                ></FontAwesomeIcon>
                                              </>
                                            )}
                                          </span>
                                          {point}
                                        </List.Item>
                                      ))}
                                    </>
                                  ) : (
                                    <></>
                                    // <>
                                    //   {aiRewriteSuggetions?.map((point, i) => (
                                    //     <List.Item
                                    //       key={i}
                                    //       onClick={() => {
                                    //         handleCheckboxChange(point);
                                    //       }}
                                    //       style={{ cursor: "pointer" }}
                                    //     >
                                    //       <span>
                                    //         {selectedItems?.includes(point) ? (
                                    //           <>
                                    //             <FontAwesomeIcon
                                    //               className="me-1 bg-white text-primary"
                                    //               icon={faMinusCircle}
                                    //               size="lg"
                                    //             ></FontAwesomeIcon>
                                    //           </>
                                    //         ) : (
                                    //           <>
                                    //             <FontAwesomeIcon
                                    //               className="me-1 bg-white text-primary"
                                    //               icon={faPlusCircle}
                                    //               size="lg"
                                    //             ></FontAwesomeIcon>
                                    //           </>
                                    //         )}
                                    //       </span>
                                    //       {point}
                                    //     </List.Item>
                                    //   ))}
                                    // </>
                                  )}
                                </>
                              </List>
                            </>
                          ) : (
                            <div className="d-flex justify-content-center my-3 text-muted">
                              <Trans
                                i18nKey="noSuggestions"
                                defaults="No Suggestions"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </Popover>
                  }
                >
                  <textarea
                    onClick={PopOverclose}
                    className="form-control mt-2"
                    name="description"
                    rows="10"
                    placeholder={
                      t("enter", {
                        defaultValue: "Enter",
                      }) +
                      " " +
                      t("Description", {
                        defaultValue: "Description",
                      })
                    }
                    onChange={(value) => {
                      setSelectedText("");
                      onChange(value);
                      setShowText(getValues().experience[expIndex].description);
                      setSelectedItems(
                        getValues().experience[expIndex].description
                      );
                      setCurrentIndex(
                        getValues().experience[expIndex].description?.length
                      );
                    }}
                    onFocus={handleFocus}
                    onKeyDown={handleKeyUp}
                    value={value}
                    onMouseUp={handleTextSelection}
                  />
                </Whisper>
              </>
            )}
          />
        ) : (
          <Whisper
            placement="auto"
            trigger="none"
            ref={rewriteRef}
            speaker={
              <Popover
                className="text-dark"
                style={{
                  width: `${descriptionWidth}px`,
                  height: "200px",
                  overflowX: "hidden",
                  overflowY: "scroll",
                }}
              >
                <div className=" border-bottom d-flex justify-content-between align-items-center">
                  <div>
                    <h6>Rewrite Suggestions</h6>
                  </div>
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={
                      <Tooltip>click here to close the suggestions</Tooltip>
                    }
                  >
                    <div className="text-end d-flex py-2 justify-content-end">
                      <FontAwesomeIcon
                        icon={faXmark}
                        size="lg"
                        className="text-muted cursorpointer"
                        onClick={() => {
                          setrewritediv(false);
                          setSelectedText("");
                        }}
                      />
                    </div>
                  </Whisper>
                </div>

                {generatedescrewriteloading ? (
                  <Placeholder.Grid columns={5} active />
                ) : (
                  <>
                    {aiRewriteSuggetions?.map((point, i) => (
                      <List.Item
                        key={i}
                        onClick={() => {
                          handleaddrewrite(point);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span>
                          <FontAwesomeIcon
                            className="me-1 bg-white text-primary"
                            icon={faCircleDot}
                            size="lg"
                          ></FontAwesomeIcon>
                        </span>
                        {point}
                      </List.Item>
                    ))}
                  </>
                )}
              </Popover>
            }
          >
            <div
              onClick={rewritePopOverclose}
              className="card"
              style={{ height: "150px", overflow: "scroll" }}
            >
              <div className="card-body">
                <ul
                  className="ps-0"
                  style={{ listStyleType: "none" }}
                  // onMouseUp={handleTextSelection}
                >
                  {getValues()
                    .experience[expIndex].description?.split("\n")
                    ?.map((x, i) => {
                      return (
                        <li
                          key={i}
                          // onClick={() => {
                          //   handleaddrewrite(point);
                          // }}
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            fontSize: "medium",
                          }}
                          className={`py-1 ${
                            selectedText.includes(x)
                              ? `text-primary seclecdrewritetext${expIndex}`
                              : "Unselectedrewritetext"
                          }`}
                        >
                          {x}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </Whisper>
        )}
      </div>
      {/* {rewritediv && (
        <div
          className="position-absolute w-100"
          style={{ zIndex: "1000", top: "600px", maxHeight: "150px" }}
        >
          <div className="card shadow-sm">
            <div className="card-header border-bottom">
              <div className="text-end d-flex py-2 justify-content-end">
                <FontAwesomeIcon
                  icon={faXmark}
                  size="lg"
                  className="text-muted cursorpointer"
                  onClick={() => {
                    setrewritediv(false);
                    setSelectedText("");
                  }}
                />
              </div>
            </div>
            <div className="card-body">
              <>
                {generatedescrewriteloading ? (
                  <Placeholder.Grid columns={5} active />
                ) : (
                  <>
                    {aiRewriteSuggetions?.map((point, i) => (
                      <List.Item
                        key={i}
                        onClick={() => {
                          handleaddrewrite(point);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span>
                          <FontAwesomeIcon
                            className="me-1 bg-white text-primary"
                            icon={faCircleDot}
                            size="lg"
                          ></FontAwesomeIcon>
                        </span>
                        {point}
                      </List.Item>
                    ))}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default ExperienceDescription;