import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Loader } from "rsuite";
import usePost from "../../../customhooks/UsePost";
import { Trans } from "react-i18next";
function SimilarJob({ JobDetails }) {
  const [similarJobs, setSimilarJobs] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState(3);

  const SampleNextArrow = (props) => {
    const { className, style, onClick, currentSlide, slideCount } = props;

    return (
      <span onClick={onClick}>
        <i className="fa fa-chevron-right fa-l customArrow nextArrow"></i>
      </span>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick, currentSlide, slideCount } = props;

    return (
      <span onClick={onClick}>
        <i className="fa fa-chevron-left fa-l customArrow  prevArrow "></i>
      </span>
    );
  };

  const {
    post: Recomandedjobs,
    data: recomdeddata,
    isLoading: RecomandedjobsLoading,
  } = usePost("/getRecommendedJobs");

  useEffect(() => {
    if (JobDetails?.clientID && JobDetails?._id && JobDetails?.companyID) {
      Recomandedjobs({
        clientID: JobDetails?.clientID,
        campaignID: JobDetails?._id,
        companyID: JobDetails?.companyID,
        Projection : [
          "title",
          "displayName",
          "job.details.jobTitle.text",
          "job.requirements.yearsOfExperience",
          "job.details.locations",
          "job.details.offeredSalary"
        ]
      })
        .then((res) => {
          if (res?.result?.data?.status) {
            setSimilarJobs(res?.result?.data?.result);

            if (window.innerWidth < 770) {
              setSlidesToShow(1);
            } else if (window.innerWidth < 980) {
              setSlidesToShow(2);
            } else {
              setSlidesToShow(3);
            }
          }
        })
        .catch((error) => {
          console.error("Error while fetching Recomadedjobs:", error);
        });
    }
  }, [JobDetails?.clientID]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: similarJobs.length == 1 ? 1 : similarJobs.length > 2 ? 3 : 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    swipeToSlide: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            similarJobs.length == 1 ? 1 : similarJobs.length > 2 ? 3 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: similarJobs.length > 1 ? 2 : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
      <div className=" card p-5">
        <div className="col-12">
          <h3 className="text-capitalize heading">
            <Trans defaults="Similar Jobs" i18nKey="similarJobs" />
          </h3>
          {RecomandedjobsLoading ? (
            <Loader className="loaderContainer" />
          ) : similarJobs.length > 0 ? (
            <Slider {...settings}>
              {similarJobs.map((job, index) => (
                <CustomSlide
                  key={job._id}
                  job={job}
                  className="card bg-light border text-dark slide "
                />
              ))}
            </Slider>
          ) : (
            <div className="card-body">
              <div className="loaderContainer">
                <h4>
                  <Trans
                    defaults="No Jobs Available"
                    i18nKey="noJobsAvailable"
                  />
                </h4>
              </div>
            </div>
          )}
        </div>
      </div>
  );
}

export default SimilarJob;

function CustomSlide({ index, job, ...props }) {
  return (
    <div>
      <div className="card bg-light border text-dark mx-2 py-2 px-3  ">
        <div className="card-body ">
          <div className="py-2 ">
            <a
              href={`/careers/jobdetails/${job._id}`}
              className="anchurTagLink"
            >
              {job?._source?.displayName && job?._source?.displayName == "Job Title" ? 
                <h6 className="textOverflow">{job._source?.job?.details?.jobTitle?.text ?? "NA"}</h6> 
                : 
                <h6 className="textOverflow">{job._source?.title ?? "NA"}</h6>
              }
            </a>
            {/* <div className="card-subtitle text-muted textOverflow">
              {job._source?.job?.details?.jobTitle?.text ?? "NA"}
            </div> */}

            <div className="font-size-10pt textOverflow">
            <Trans defaults="Location"  i18nKey="location" /> {/* Location */}:{" "}
              {job._source?.job?.details?.locations?.[0]?.text ? (
                          job._source?.job?.details?.locations?.[0]?.text
                        ) : job._source?.job?.details?.locations?.[0]?.city ? (
                          <>
                            {job._source?.job?.details?.locations?.[0].city}, {job._source?.job?.details?.locations?.[0].state},{" "}
                            {job._source?.job?.details?.locations?.[0].country}
                          </>
                        ) : (
                          "NA"
                        )}
            </div>

            <div className="font-size-10pt textOverflow">
            <Trans defaults="Experience"  i18nKey="experience" /> {/*  Experience*/}: {" "}
            {(job._source?.job?.requirements
                          ?.yearsOfExperience?.minYears == 0 || job._source?.job?.requirements
                          ?.yearsOfExperience?.minYears) && job._source?.job?.requirements
                          ?.yearsOfExperience?.maxYears ? 
                          <span title={job._source?.job?.requirements
                            ?.yearsOfExperience?.minYears + " - " + job._source?.job?.requirements
                            ?.yearsOfExperience?.maxYears + " Years" }>
                            {job._source?.job?.requirements
                          ?.yearsOfExperience?.minYears + " - " + job._source?.job?.requirements
                          ?.yearsOfExperience?.maxYears + " Years" }
                          </span> : <span>
                            <Trans
                              i18nKey="n/a"
                              defaults="N/A"
                            />
                          </span>

                        }
            </div>

            <div className="text-capitalize font-size-10pt textOverflow">
            <Trans defaults="salary"  i18nKey="Salary" /> {/* Location */}:{" "}
            {(job._source?.job?.details?.offeredSalary?.minvalue != 0 
                       || job._source?.job?.details?.offeredSalary?.minvalue == 0)
                       && job._source?.job?.details?.offeredSalary?.maxvalue != 0 ?
                       <span>
                        {
                          job._source?.job?.details?.offeredSalary?.currency == "USD" ? "$" + job._source?.job?.details?.offeredSalary?.minvalue + " - " + "$" + job._source?.job?.details?.offeredSalary?.maxvalue
                          : job._source?.job?.details?.offeredSalary?.currency +" "+ job._source?.job?.details?.offeredSalary?.minvalue + " - " + job._source?.job?.details?.offeredSalary?.currency +" "+ job._source?.job?.details?.offeredSalary?.maxvalue
                        }
                        {" "+job._source?.job?.details?.offeredSalary?.period}
                       </span> : <span>
                            {/* {" "} */}
                            <Trans
                              i18nKey="n/a"
                              defaults="N/A"
                            />
                          </span>
                      }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
